






import GameProviderReportingApiDoc from '@/components/game-provider/GameProviderReportingApiDoc.vue';
import GameProviderReportingApiKey from '@/components/game-provider/GameProviderReportingApiKey.vue';

export default {
  name: 'SuperAdminGameProviderReportingApi',
  components: {
    GameProviderReportingApiDoc,
    GameProviderReportingApiKey
  },
  computed: {
    accountId(): number | null {
      return +this.$route.params.id || null;
    }
  }
};
